import React from 'react'
import { IMainDataCardProps } from './main-data-card-props.interface'
import { ReactComponent as InfoIcon } from '../../icons/info.svg'
import getArrow from '../../helpers/getArrow'
import {
  formattedNumber,
  simpleFormattedNumber
} from '../../helpers/helperFunctions'
import { Tooltip } from 'antd'

const MainDataCard: React.FC<IMainDataCardProps> = ({
  cardData,
  id
}: IMainDataCardProps) => {
  function formatDateTime(lastUpdate: {
    seconds: number
    nanoseconds: number
  }) {
    // Convert the seconds and nanoseconds to milliseconds
    const milliseconds =
      lastUpdate.seconds * 1000 + lastUpdate.nanoseconds / 1000000

    // Create a Date object
    const date = new Date(milliseconds)

    // Format the date as "01 September 2023"
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    })
  }

  return (
    <div className="flex flex-col sm:flex-row justify-between items-stretch bg-white shadow-lg w-full max-w-6xl mx-auto rounded-2xl border-2 border-cons-blue/50 mt-6 overflow-hidden min-h-[218px]">
      {/* Left Section: Logo */}
      <div className="flex items-center justify-center self-center min-w-[224px] sm:w-[274px] grow-0 basis-0 aspect-[1/1]">
        {cardData.img_url && (
          <img
            src={cardData.img_url}
            alt={`${cardData.name} logo`}
            className="w-full h-full object-contain max-w-[274px]"
          />
        )}
      </div>

      {/* Middle Section: BrandList Information */}
      <div className="flex flex-col justify-between bg-cons-blue-step-down/25 p-4 sm:p-6 max-sm:text-center border-l border-cons-blue/20 grow basis-0">
        <h3 className="text-xl font-semibold">{cardData.name}</h3>
        <div>
          {cardData.product_count && (
            <p className="text-sm text-cons-gray mt-2">
              Number of Items:{' '}
              <span className="text-cons-almostBlack">
                {cardData.product_count}
              </span>
            </p>
          )}
          {cardData.company_name && (
            <p className="text-sm text-cons-gray mt-2">
              Company Name:{' '}
              <span className="text-cons-almostBlack">
                {cardData.company_name}
              </span>
            </p>
          )}
          {id && (
            <p className="text-sm text-cons-gray mt-2">
              Item ID: <span className="text-cons-almostBlack">{id}</span>
            </p>
          )}
          {cardData.category_count && (
            <p className="text-sm text-cons-gray mt-1">
              Number of Categories:{' '}
              <span className="text-cons-almostBlack">
                {cardData.category_count}
              </span>
            </p>
          )}
          {cardData.category && (
            <p className="text-sm text-cons-gray mt-1">
              Product Category:{' '}
              <span className="text-cons-almostBlack">{cardData.category}</span>
            </p>
          )}
          {cardData.report_date ? (
            <p className="text-sm text-cons-gray mt-1">
              Last update:{' '}
              <span className="text-cons-almostBlack">
                {formatDateTime(cardData.report_date)}
              </span>
            </p>
          ) : cardData.timestamps?.updated_at ? (
            <p className="text-sm text-cons-gray mt-1">
              Last update:{' '}
              <span className="text-cons-almostBlack">
                {formatDateTime(cardData.timestamps.updated_at)}
              </span>
            </p>
          ) : (
            ''
          )}
        </div>
      </div>

      {/* Right Section: Consumerly Stats */}
      <div className="flex flex-col bg-cons-blue-step-down/25 sm:text-center max-sm:w-full min-w-[28%] grow py-3 basis-0 sm:max-w-[274px]">
        <div className="space-y-8 sm:space-y-0 border-l-cons-blue/25 border-l-[1.5px] border-solid max-sm:border-0 m-4 px-4 my-0 max-sm:items-center flex flex-col justify-evenly items-center h-full">
          {/* Consumerly Index */}
          {cardData.stats?.consumerly?.index?.current ? (
            <div className="flex justify-between w-full space-x-4 m-0">
              <div className="text-left">
                <p className="text-xl font-bold inline-block text-cons-purple">
                  {cardData.stats.consumerly.index.current}&nbsp;
                  <span className="text-xs">/&nbsp;100</span>
                </p>
                <p className="text-sm text-gray-500 flex items-center">
                  Consumerly Index{' '}
                  <span className="ml-1 text-xs text-gray-400 cursor-pointer">
                    <Tooltip title="This number describes the overall customer satisfaction of a product. It is based on the sentiment found within the reviews instead of the traditional star rating.">
                      <InfoIcon />
                    </Tooltip>
                  </span>
                </p>
              </div>
              {cardData.stats?.consumerly?.index?.previous && (
                <div className="flex flex-col justify-center text-right">
                  <p className="text-sm font-semibold text-cons-gray text-4">
                    {cardData.stats.consumerly.index.previous}{' '}
                    {getArrow(cardData.stats.consumerly.index.arrow, 'inline')}
                  </p>
                  <p className="text-xs text-cons-gray-step-up-50">
                    Previous Month
                  </p>
                </div>
              )}
            </div>
          ) : (
            ''
          )}

          {/* Consumerly Ranking */}
          {cardData.stats?.consumerly?.ranking?.current ? (
            <div className="space-x-4 m-0 w-full">
              <div className="text-left">
                <div>
                  <p className="text-xl font-bold text-cons-purple">
                    {cardData.stats.consumerly.ranking.current}{' '}
                  </p>
                  <p className="text-sm text-gray-500">Consumerly Ranking</p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {/* Total Reviews */}
          {cardData.stats?.consumerly?.total_reviews?.current ? (
            <div className="flex space justify-between w-full space-x-4 m-0 ">
              <div className="text-left">
                <p className="text-xl font-bold text-cons-purple">
                  {formattedNumber(
                    cardData.stats.consumerly.total_reviews.current
                  )}
                </p>
                <p className="text-sm text-gray-500 flex items-center">
                  Total Reviews
                </p>
              </div>
              <div className="flex flex-col justify-center text-right">
                <p className="text-sm font-semibold text-cons-gray text-4">
                  {formattedNumber(
                    cardData.stats.consumerly.total_reviews.previous
                  )}{' '}
                  {getArrow(
                    cardData.stats.consumerly.total_reviews.arrow,
                    'inline'
                  )}
                </p>
                <p className="text-xs text-cons-gray-step-up-50">
                  Previous Month
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
          {/* Total Reviews */}
          {cardData.stats?.consumerly?.category_ranking?.current ? (
            <div className="flex space justify-between w-full space-x-4">
              <div className="text-left">
                <span className="text-xl font-bold text-cons-purple">
                  {formattedNumber(
                    cardData.stats.consumerly.category_ranking.current
                  )}
                  &nbsp;
                </span>
                {cardData.stats.consumerly.category_ranking.total && (
                  <span className="text-xs text-cons-green-step-up/80 font-bold">
                    Total&nbsp;
                    {simpleFormattedNumber(
                      cardData.stats.consumerly.category_ranking.total
                    )}
                  </span>
                )}
                <p className="text-sm text-gray-500 flex items-center">
                  Category Ranking
                </p>
              </div>
              <div className="flex flex-col justify-center text-right">
                <p className="text-sm font-semibold text-cons-gray text-4">
                  {formattedNumber(
                    cardData.stats.consumerly.category_ranking.previous
                  )}{' '}
                  {getArrow(
                    cardData.stats.consumerly.category_ranking.arrow,
                    'inline'
                  )}
                </p>
                <p className="text-xs text-cons-gray-step-up-50">
                  Previous Month
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}
export default MainDataCard
