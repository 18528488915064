import React, { FormEvent, useState, useEffect } from 'react'
import { doSignInWithEmailAndPassword } from '../../firebase/auth'
import { useNavigate } from 'react-router-dom'
import yourImage from '../../icons/login.png'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const Login = () => {
  const navigate = useNavigate()
  const auth = getAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isSigningIn, setIsSigningIn] = useState(false)
  const [isErrorMessage, setIsErrorMessage] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in, redirect to the home page (/brand-list)
        navigate('/brand-list')
      }
    })

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [auth, navigate])

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isSigningIn) {
      try {
        setIsSigningIn(true)

        await doSignInWithEmailAndPassword(email, password)

        navigate('/brand-list')
      } catch (error) {
        setIsErrorMessage(true)
        console.error(error)
      } finally {
        setIsSigningIn(false)
      }
    }
  }

  return (
    <div className="min-h-screen flex flex-col md:flex-row items-start justify-center bg-white">
      <div className="w-full flex justify-center items-start md:w-1/3">
        <img
          src={yourImage}
          alt="Consumerly Illustration"
          className="w-3/4 md:w-full h-auto mt-1"
        />
      </div>
      <div className="w-full md:w-1/2 flex justify-center p-8 md:p-16">
        <div className="w-full max-w-md">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-800 mb-4">
            Log in to your account
          </h2>
          {isErrorMessage && (
            <div className="text-red-500 mb-4 bg-red-100 rounded p-2">
              Invalid email or password
            </div>
          )}
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                type="email"
                id="email"
                placeholder="Email Address"
                className="outline-none mt-1 block w-full px-4 py-2 border-b border-gray-300 bg-white focus:bg-transparent focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                type="password"
                id="password"
                placeholder="Password"
                className="outline-none mt-1 block w-full px-4 py-2 border-b border-gray-300 bg-white focus:bg-transparent focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-md text-lg font-semibold hover:bg-blue-700 transition duration-300"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
