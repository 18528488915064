import React, { createContext, useState, useEffect } from 'react'
import { onSnapshot, doc, getFirestore } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { IProductData } from '../../containers/brand/brand.interface'

export type ProductContextValue = IProductData | null

const ProductContext = createContext<ProductContextValue | null>(null)
export const ProductProvider = ({ children }: any) => {
  const { brandId, productId } = useParams()
  const [productData, setProductData] = useState<IProductData | null>(null)

  useEffect(() => {
    const db = getFirestore()
    if (brandId && productId) {
      const productRef = doc(db, 'brands', brandId, 'products', productId)
      const unsubscribeProduct = onSnapshot(productRef, (snapshot) => {
        if (snapshot.exists()) {
          // @ts-ignore
          setProductData(snapshot.data())
        }
      })

      return () => {
        unsubscribeProduct()
      }
    }
  }, [brandId, productId])

  return (
    // @ts-ignore
    <ProductContext.Provider value={productData}>
      {children}
    </ProductContext.Provider>
  )
}

export default ProductContext
