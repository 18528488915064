import React, { useCallback, useEffect, useState } from 'react'
import { db } from '../../firebase/firebase'
import { collection, query, getDocs, where } from 'firebase/firestore'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import { Input, TablePaginationConfig, Tooltip } from 'antd'
import { getCategoryListConfig } from './category-list-config'
import { formattedNumber } from '../../helpers/helperFunctions'

import DataTable from '../../components/shared/Table/DataTable'
import { ICategoryData } from './category.interface'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import DefaultEmptyCard from '../../components/defaultEmptyCard/DefaultEmptyCard'
import Loader from '../../components/shared/Loader/Loader'

const CategoryList = () => {
  const { userData } = useUser()
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [categories, setCategories] = useState<ICategoryData[]>([])
  const [isEmptyCategories, setIsEmptyCategories] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredCategories, setFilteredCategories] = useState<ICategoryData[]>(
    []
  )
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearch = useCallback(
    debounce((value: string) => {
      onSearch(value)
    }, 1000),
    [categories]
  )

  const handleSearch = (value: string) => {
    setSearchValue(value)
    debounceSearch(value)
  }

  const onSearch = (value: string) => {
    const filteredData = categories.filter((category) =>
      category.name?.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredCategories(filteredData)
  }

  useEffect(() => {
    // Load the first page initially
    userData?.category_ids && fetchCategories(userData.category_ids)
  }, [userData])

  const fetchCategories = async (categoryIds: string[]) => {
    try {
      setIsLoading(true)

      if (categoryIds.length === 0) {
        setIsEmptyCategories(true)
        setCategories([])
        setFilteredCategories([])
        return
      }

      const categoriesRef = collection(db, 'categories')
      const chunks = []
      const allDocs: any[] = []

      // Split into chunks of 10 (Firestore's 'in' query limit)
      for (let i = 0; i < categoryIds.length; i += 10) {
        chunks.push(categoryIds.slice(i, i + 10))
      }

      // Fetch all chunks concurrently
      const queries = chunks.map((chunk) =>
        query(categoriesRef, where('__name__', 'in', chunk))
      )

      const snapshots = await Promise.all(queries.map(getDocs))

      // Process the results
      const foundIds = new Set<string>()
      snapshots.forEach((snapshot) =>
        snapshot.forEach((doc) => {
          allDocs.push({ id: doc.id, ...doc.data() })
          foundIds.add(doc.id) // Track found IDs
        })
      )

      // Check for missing IDs
      const missingIds = categoryIds.filter((id) => !foundIds.has(id))
      if (missingIds.length > 0) {
        console.warn('Missing categories:', missingIds)
      }

      // Update state with all fetched data
      setCategories(allDocs)
      setFilteredCategories(allDocs)

      // Handle case where no categories were found
      if (allDocs.length === 0) {
        setIsEmptyCategories(true)
      }
    } catch (error) {
      console.error('Error fetching categories:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const rowSelection = {
    columnWidth: '10px',
    selectedRowKeys,
    onChange: (newSelectedRowKeys: string[]) =>
      setSelectedRowKeys(newSelectedRowKeys)
  }

  const paginationConfig: TablePaginationConfig = {
    pageSize: 10,
    total: searchValue ? filteredCategories.length : categories.length
  }

  return (
    <div className="max-w-6xl w-4/5 mx-auto mt-8 mb-10 overflow-hidden">
      {isLoading ? (
        <Loader size="large" />
      ) : (
        categories.length > 0 && (
          <>
            <div className="flex justify-between">
              <div className="">
                <div className="">
                  <div className="my-2 text-xl font-semibold">
                    Category Insights
                  </div>
                </div>
                <Tooltip title="Enter Category Name">
                  <Input
                    className="bg-transparent text-blue font-medium placeholder-gray-400 focus:outline-none w-60 h-10"
                    placeholder="Search by name"
                    prefix={<SearchOutlined className="text-cons-blue mr-1" />}
                    value={searchValue}
                    onChange={(e) => handleSearch(e.target.value)}
                    allowClear
                  />
                </Tooltip>
              </div>
              <div className="mt-4 text-right text-cons-blue">
                Showing {formattedNumber(categories.length)} categories
              </div>
            </div>
            <div className="mt-4 shadow-[1px_2px_5px_1px_rgba(29,42,68,0.12)]">
              <DataTable
                loading={isLoading || !userData}
                // @ts-ignore
                rowSelection={rowSelection}
                columns={getCategoryListConfig()}
                dataSource={searchValue ? filteredCategories : categories}
                rowKey="id"
                rowClassName="[&_th]:text-cons-blue border border-solid border-cons-gray-step-down rounded-lg"
                locale={{
                  emptyText: 'Data is being prepared. Please try again later.'
                }}
                pagination={paginationConfig}
              />
            </div>
          </>
        )
      )}
      {(isEmptyCategories || !userData?.category_ids) && (
        <DefaultEmptyCard titleKey="Category" />
      )}
    </div>
  )
}

export default CategoryList
