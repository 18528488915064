import React from 'react'
import { TableProps } from 'antd'
import { ICategoryData } from './category.interface'
import { simpleFormattedNumber } from '../../helpers/helperFunctions'
import { Link } from 'react-router-dom'

export function getCategoryListConfig(): TableProps<ICategoryData>['columns'] {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 40,
      key: 'name',
      ellipsis: true,
      className: 'font-semibold text-[#003C5B]',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => {
        return {
          style: {
            color: '#003C5B'
          }
        }
      }
    },
    {
      title: 'Item Count',
      width: 20,
      dataIndex: 'item_count',
      key: 'itemCount',
      ellipsis: true,
      className: 'font-medium text-[#003C5B]',
      render: (value) => (value ? simpleFormattedNumber(value) : ''),
      // @ts-ignore
      sorter: (a: any, b: any, order: 'ascend' | 'descend') => {
        const indexA =
          a.item_count ?? (order === 'ascend' ? Infinity : -Infinity)
        const indexB =
          b.item_count ?? (order === 'ascend' ? Infinity : -Infinity)
        return indexA - indexB
      },
      sortDirections: ['ascend', 'descend'],

      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Consumerly Index',
      width: '25px',
      align: 'center',
      key: 'consumerly-index',
      dataIndex: 'consumerly_index',
      className: 'font-bold text-[#422684]',
      // @ts-ignore
      sorter: (a: any, b: any, order: 'ascend' | 'descend') => {
        const indexA =
          a.consumerly_index ?? (order === 'ascend' ? Infinity : -Infinity)
        const indexB =
          b.consumerly_index ?? (order === 'ascend' ? Infinity : -Infinity)
        return indexA - indexB
      },
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Number of reviews',
      width: '25px',
      align: 'center',
      key: 'average-reviews',
      dataIndex: 'average_reviews',
      className: 'font-medium text-[#003C5B]',
      render: (value) => (value ? simpleFormattedNumber(value) : ''),
      // @ts-ignore
      sorter: (a: any, b: any, order: 'ascend' | 'descend') => {
        const indexA =
          a.average_reviews ?? (order === 'ascend' ? Infinity : -Infinity)
        const indexB =
          b.average_reviews ?? (order === 'ascend' ? Infinity : -Infinity)
        return indexA - indexB
      },
      sortDirections: ['ascend', 'descend'],

      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: '',
      width: '20px',
      dataIndex: '',
      key: 'action',
      render: (data) => (
        <Link
          to={`/category-details/${data.id}`}
          className="text-blue-500 underline"
        >
          See Insights
        </Link>
      )
    }
  ]
}
