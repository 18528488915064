import React, { useState } from 'react'
import { ProductIdeas } from '../../category.interface'
import { Button } from 'antd'
import { ReactComponent as AnalysisIcon } from '../../../../icons/analysis.svg'
import { ReactComponent as AILoader } from '../../../../icons/ai-work.svg'
import { formatYearMonth } from '../../../../helpers/helperFunctions'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw' // For GitHub-flavored Markdown (tables, strikethrough, etc.)

interface ExpandableBlockProps {
  title: string
  markdownText: string
}
const ExpandableBlock: React.FC<ExpandableBlockProps> = ({
  title,
  markdownText
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => setIsExpanded((prev) => !prev)

  return (
    <div className="rounded-lg p-4 mb-4">
      <div className="flex justify-between items-center mb-2">
        <h2 className="font-bold text-xl text-cons-blue">{title}</h2>
      </div>
      <div className="relative overflow-hidden">
        <div
          className={`text-box transition-all duration-300 bg-white px-6 py-4 rounded-md ${
            isExpanded ? 'max-h-full shadow-b' : 'max-h-40 shadow-b'
          }`}
          style={{ position: 'relative' }}
        >
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {markdownText}
          </ReactMarkdown>
        </div>
        <button
          onClick={toggleExpand}
          className="text-cons-blue font-medium ml-8"
          aria-expanded={isExpanded}
          aria-controls="expandable-content"
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      </div>
    </div>
  )
}

interface ProductDetailsProps {
  isMiniLoader: boolean
  handleGenerateIdeas: () => void
  productIdeasData?: ProductIdeas
  reportStatus?: 'pending' | 'completed' | 'none'
  reportMonth?: string
  reportDate?: { seconds: number; nanoseconds: number }
}

const NewProductIdeas: React.FC<ProductDetailsProps> = ({
  isMiniLoader,
  productIdeasData,
  handleGenerateIdeas,
  reportMonth,
  reportStatus,
  reportDate
}: ProductDetailsProps) => {
  return (
    <div className="container mx-auto p-4">
      <div className="bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
        {reportStatus === 'pending' && (
          <div className="grid min-h-[300px] place-items-center">
            <p className="text-center font-semibold text-lg">
              Please wait while our AI analysis is being prepared. <br />
              You can come back to this page later.
            </p>
            <AILoader width="200px" height="200px" />
          </div>
        )}
        {productIdeasData &&
          reportStatus === 'completed' &&
          reportStatus === 'completed' && (
            <>
              {productIdeasData?.recommendation?.full_text && (
                <ExpandableBlock
                  title={productIdeasData?.recommendation?.title}
                  markdownText={productIdeasData?.recommendation?.full_text}
                />
              )}
              {productIdeasData?.desirable_product_attributes?.full_text && (
                <ExpandableBlock
                  title={productIdeasData?.desirable_product_attributes?.title}
                  markdownText={
                    productIdeasData?.desirable_product_attributes?.full_text
                  }
                />
              )}
            </>
          )}

        {reportDate &&
          reportStatus !== 'pending' &&
          reportMonth !== formatYearMonth(reportDate) && (
            <div className="flex flex-col sm:flex-row px-8">
              <div className="p-3 w-6/12 max-sm:w-full text-center">
                <AnalysisIcon className="w-2/3 " />
              </div>
              <div className="ml-4 p-6 mb-4 w-6/12 max-sm:w-full flex items-center">
                <div>
                  <h3 className="mb-4 font-semibold">
                    Would you like to start analyzing this category now?
                  </h3>
                  <div className="mb-4">
                    After activating this category, you will get unlimited
                    access to the Analysis & Recommendations for this month.
                  </div>
                  <Button
                    type="primary"
                    className="bg-cons-blue hover:bg-cons-blue-step-down h-10"
                    onClick={() => handleGenerateIdeas()}
                    loading={isMiniLoader}
                  >
                    Generate analysis
                  </Button>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}

export default NewProductIdeas
