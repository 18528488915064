import React from 'react'
import { ReactComponent as LeftArrow } from '../../../icons/arrow-left.svg'
import { useNavigate } from 'react-router-dom'

const BackButton: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate(-1)}
      className="bg-cons-blue/15 absolute w-10 h-10 z-1 -left-14 top-15 flex items-center justify-center rounded cursor-pointer max-md:hidden"
    >
      <LeftArrow />
    </div>
  )
}

export default BackButton
