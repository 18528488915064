import React from 'react'
import { TableProps } from 'antd'
// import { ICategoryData } from './category.interface'
import { simpleFormattedNumber } from '../../helpers/helperFunctions'
import { Link } from 'react-router-dom'
import { IBrandData } from './brand.interface'

export function getBrandListConfig(): TableProps<IBrandData>['columns'] {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 40,
      key: 'name',
      ellipsis: true,
      className: 'font-semibold text-[#003C5B]',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => {
        return {
          style: {
            color: '#003C5B'
          }
        }
      }
    },
    {
      title: 'Product Count',
      width: 20,
      dataIndex: 'product_count',
      key: 'productCount',
      ellipsis: true,
      className: 'font-medium text-[#003C5B]',
      render: (value) => (value ? simpleFormattedNumber(value) : ''),
      // @ts-ignore
      sorter: (a: any, b: any, order: 'ascend' | 'descend') => {
        const indexA =
          a.product_count ?? (order === 'ascend' ? Infinity : -Infinity)
        const indexB =
          b.product_count ?? (order === 'ascend' ? Infinity : -Infinity)
        return indexA - indexB
      },
      sortDirections: ['ascend', 'descend'],

      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Consumerly Index',
      width: '25px',
      align: 'center',
      key: 'consumerly-index',
      dataIndex: ['stats', 'consumerly', 'index', 'current'],
      className: 'font-bold text-[#422684]',
      sorter: (a, b) => {
        const aValue = a.stats?.consumerly?.index?.current || 0 // Handle undefined values
        const bValue = b.stats?.consumerly?.index?.current || 0 // Handle undefined values
        return aValue - bValue
      },
      sortDirections: ['ascend', 'descend'],
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: '',
      width: '20px',
      dataIndex: '',
      key: 'action',
      render: (data) => (
        <Link to={`/brand/${data.id}`} className="text-blue-500 underline">
          See Insights
        </Link>
      )
    }
  ]
}
