import React, { useCallback, useEffect, useState } from 'react'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../../firebase/firebase'
import { useNavigate } from 'react-router-dom'
import { Input, TablePaginationConfig, Tooltip } from 'antd'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import { IBrandData } from './brand.interface'
import { debounce } from 'lodash'
import { SearchOutlined } from '@ant-design/icons'
import { formattedNumber } from '../../helpers/helperFunctions'
import DataTable from '../../components/shared/Table/DataTable'
import { getBrandListConfig } from './brand-list-config'
import DefaultEmptyCard from '../../components/defaultEmptyCard/DefaultEmptyCard'
import Loader from '../../components/shared/Loader/Loader'

const BrandList = () => {
  const { userData } = useUser()
  const navigate = useNavigate()
  const [brands, setBrands] = useState<IBrandData[]>([])
  const [isEmptyBrands, setIsEmptyBrands] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [filteredBrands, setFilteredBrands] = useState<IBrandData[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearch = useCallback(
    debounce((value: string) => {
      onSearch(value)
    }, 1000),
    [brands]
  )

  useEffect(() => {
    if (userData?.brand_ids?.length === 1) {
      navigate(`/brand/${userData?.brand_ids[0]}`)
    }
  }, [userData])

  const handleSearch = (value: string) => {
    setSearchValue(value)
    debounceSearch(value)
  }

  const onSearch = (value: string) => {
    const filteredData = brands.filter((brand) =>
      brand.name?.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredBrands(filteredData)
  }

  useEffect(() => {
    // Load the first page initially
    userData?.brand_ids && fetchBrands(userData.brand_ids)
  }, [userData])

  const fetchBrands = async (brandIds: string[]) => {
    try {
      setIsLoading(true)
      if (brandIds.length === 0) {
        setIsEmptyBrands(true)
        setBrands([])
        return
      }

      const brandsRef = collection(db, 'brands')
      const chunks = []
      const allDocs: any[] = []

      // Split into chunks of 10 (Firestore's 'in' query limit)
      for (let i = 0; i < brandIds.length; i += 10) {
        chunks.push(brandIds.slice(i, i + 10))
      }

      // Fetch all chunks concurrently
      const queries = chunks.map((chunk) =>
        query(brandsRef, where('__name__', 'in', chunk))
      )

      const snapshots = await Promise.all(queries.map(getDocs))
      snapshots.forEach((snapshot) =>
        snapshot.forEach((doc) => allDocs.push({ id: doc.id, ...doc.data() }))
      )

      // Update state with all fetched data
      setBrands(allDocs)
      setFilteredBrands(allDocs)
    } catch (error) {
      console.error('Error fetching categories:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const paginationConfig: TablePaginationConfig = {
    pageSize: 10,
    total: searchValue ? filteredBrands.length : brands.length
  }

  return (
    <div className="max-w-6xl w-4/5 mx-auto mt-8 mb-10 overflow-hidden">
      {isLoading ? (
        <Loader size="large" />
      ) : (
        brands.length > 0 && (
          <>
            <div className="flex justify-between">
              <div className="">
                <div className="">
                  <div className="my-2 text-xl font-semibold">Brands</div>
                </div>
                <Tooltip title="Enter Brand Name">
                  <Input
                    className="bg-transparent text-blue font-medium placeholder-gray-400 focus:outline-none w-60 h-10"
                    placeholder="Search by name"
                    prefix={<SearchOutlined className="text-cons-blue mr-1" />}
                    value={searchValue}
                    onChange={(e) => handleSearch(e.target.value)}
                    allowClear
                  />
                </Tooltip>
              </div>
              <div className="mt-4 text-right text-cons-blue">
                Showing {formattedNumber(brands.length)} brands
              </div>
            </div>
            <div className="mt-4 shadow-[1px_2px_5px_1px_rgba(29,42,68,0.12)]">
              <DataTable
                loading={isLoading || !userData}
                // @ts-ignore
                columns={getBrandListConfig()}
                dataSource={searchValue ? filteredBrands : brands}
                rowKey="id"
                rowClassName="[&_th]:text-cons-blue border border-solid border-cons-gray-step-down rounded-lg"
                locale={{
                  emptyText: 'Data is being prepared. Please try again later.'
                }}
                pagination={paginationConfig}
              />
            </div>
          </>
        )
      )}
      {(isEmptyBrands || !userData?.brand_ids) && (
        <DefaultEmptyCard titleKey="Brand" />
      )}
    </div>
  )
}

export default BrandList
