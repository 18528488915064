const simpleFormattedNumber = (value: number) => {
  return new Intl.NumberFormat('en-US').format(value)
}

const formattedNumber = (value: number) => {
  if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(2)}M`
  } else if (value >= 1_000) {
    return `${(value / 1_000).toFixed(0)}K`
  } else {
    return new Intl.NumberFormat('en-US').format(value)
  }
}

const formatCurrency = (value: number): string => {
  if (value >= 1_000_000) {
    // Manually format millions with two decimal places
    return `$${(value / 1_000_000).toFixed(2)}M`
  } else {
    // Use Intl.NumberFormat for thousands and smaller
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact'
    }).format(value)
  }
}

// Example of returned value "2025-01"
const getCurrentMonth = (): string => {
  const currentDate = new Date()
  return `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`
}

export {
  formattedNumber,
  formatCurrency,
  simpleFormattedNumber,
  getCurrentMonth
}

export const formatYearMonth = (lastUpdate?: {
  seconds: number
  nanoseconds: number
}) => {
  if (!lastUpdate) {
    return ''
  }
  // Convert the seconds and nanoseconds to milliseconds
  const milliseconds =
    lastUpdate.seconds * 1000 + lastUpdate.nanoseconds / 1000000

  // Create a Date object
  const date = new Date(milliseconds)

  // Extract the year and month
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Ensure two-digit month

  // Return in "YYYY-MM" format
  return `${year}-${month}`
}
