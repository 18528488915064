import React from 'react'
import swotEmpty from '../../icons/swot-empty.png'
import { Button } from 'antd'
interface DefaultEmptyCardProps {
  titleKey: string
}

const DefaultEmptyCard: React.FC<DefaultEmptyCardProps> = ({ titleKey }) => {
  let itemName = ''
  switch (titleKey.toLowerCase()) {
    case 'brand':
      itemName = 'brands'
      break // Prevents fall-through
    case 'category':
      itemName = 'categories'
      break
    default:
      itemName = ''
  }

  return (
    <div className="flex flex-col md:flex-row text-center md:text-left place-content-center items-center bg-cons-blue-step-down/20 px-10 py-6 rounded-2xl">
      <div className="min-w-[100px]">
        <img
          src={swotEmpty}
          className="w-full max-w-[400px] h-auto"
          alt="Empty"
        />
      </div>
      <div className="mt-6 md:mt-0 md:ml-10 max-w-[450px]">
        <h3 className="mb-2 font-semibold">
          {`Would you like to start browsing a ${titleKey} now?`}
        </h3>
        <p>
          {`After licensing ${itemName}, you will get unlimited access to the`}
          &nbsp;
          <b>Analysis & Recommendations</b>.
        </p>
        <Button className="mt-5 bg-cons-blue p-5 font-bold text-cons-white hover:text-cons-blue hover:border-cons-blue">
          Yes, get started
        </Button>
      </div>
    </div>
  )
}

export default DefaultEmptyCard
