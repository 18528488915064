import React, { useCallback, useEffect, useState } from 'react'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import MainDataCard from '../../components/mainDataCard/MainDataCard'

import { IProductData } from '../brand/brand.interface'
import {
  getFirestore,
  doc,
  collection,
  onSnapshot,
  getDocs,
  updateDoc
} from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { Spin, Radio, message } from 'antd'
import BackButton from '../../components/shared/backButton/BackButton'
import ProductDashboard from './productDetailsTabs/dashboard/ProductDashboard'
import ConsumerProfile from './productDetailsTabs/consumeProfile/ConsumerProfile'
import { ReactComponent as DashboardBlueIcon } from '../../icons/dashboard_blue_icon.svg'
import { ReactComponent as DashboardWhiteIcon } from '../../icons/dashboard_white_icon.svg'
import { ReactComponent as ProfileWhiteIcon } from '../../icons/profile_white_icon.svg'
import { ReactComponent as ProfileBlueIcon } from '../../icons/profile_blue_icon.svg'
import { ReactComponent as AnalysisWhiteIcon } from '../../icons/analysis_white_icon.svg'
import { ReactComponent as AnalysisBlueIcon } from '../../icons/analysis_blue_icon.svg'
import { ReactComponent as ExclamationBlueIcon } from '../../icons/exclamation_thin.svg'
import AnalysisTab from './productDetailsTabs/analysis/Analysis'
import {
  IAnalysisData,
  IConsumerProfileData,
  IProductAlertsData
} from './product.interface'
import './ProductDetails.scss'
import AlertsTab from './productDetailsTabs/alerts/Alerts'
import { auth } from '../../firebase/firebase'
import axios from 'axios'

interface IDataBlockWithSortOrder extends IAnalysisData {
  sort_order: number
}

type dataTabs = 'dashboard' | 'profile' | 'analysis' | 'alerts'

const ProductDetails = () => {
  const { userData } = useUser()
  const { productId, brandId } = useParams()
  const brandDataId = userData?.brand_id || brandId
  const [messageApi, contextHolder] = message.useMessage()
  const [activeTab, setActiveTab] = useState<dataTabs>('dashboard')
  const [productData, setProductData] = useState<IProductData>()
  const [isLoading, setIsLoading] = useState(false)
  const [consumerProfileData, setConsumerProfileData] =
    useState<IConsumerProfileData>()
  const [productAlertsData, setProductAlertsData] =
    useState<IProductAlertsData>()
  const [isMiniLoader, setIsMiniLoader] = useState<boolean>(false)
  const [productAnalysisId, setProductAnalysisId] = useState('')
  const [analysisData, setAnalysisData] = useState<IAnalysisData>()
  const db = getFirestore()

  const handleTabChange = (key: dataTabs) => {
    setActiveTab(key)
    if (key === 'profile' && !consumerProfileData) {
      getProfileData()
    } else if (key === 'alerts' && !productAlertsData) {
      getAlertsData()
    }
  }

  useEffect(() => {
    if (brandDataId && productId) {
      setIsLoading(true)

      const productDocRef = doc(
        db,
        'brands',
        brandDataId,
        'products',
        productId
      )

      const unsubscribe = onSnapshot(
        productDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            setProductData(docSnapshot.data() as IProductData)
          } else {
            console.error('Product not found')
          }
          setIsLoading(false) // Stop loading when data is fetched
        },
        (error) => {
          console.error('Error fetching product:', error)
          setIsLoading(false) // Stop loading on error
        }
      )

      // Cleanup subscription on unmount or when dependencies change
      return () => unsubscribe()
    }
  }, [brandDataId, productId])

  const sortBySortOrder = (data: IAnalysisData) => {
    const dataArray: IDataBlockWithSortOrder[] = Object.values(
      data
    ) as IDataBlockWithSortOrder[]
    return dataArray.sort((a, b) => a.sort_order - b.sort_order)
  }

  const getProfileData: Function = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    try {
      if (productId && brandDataId) {
        const profileDocRef = collection(
          db,
          'brands',
          brandDataId,
          'products',
          productId,
          'consumerProfile'
        )

        // Subscribe to snapshot changes
        const profileDoc = await getDocs(profileDocRef)
        if (!profileDoc.empty) {
          //@ts-ignore
          setConsumerProfileData(profileDoc.docs[0].data())
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [userData, productData])

  const getAlertsData: Function = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    try {
      if (productId && brandDataId) {
        const alertsDocRef = collection(
          db,
          'brands',
          brandDataId,
          'products',
          productId,
          'alerts'
        )

        const alertsDoc = await getDocs(alertsDocRef)
        if (!alertsDoc.empty) {
          //@ts-ignore
          setProductAlertsData(alertsDoc.docs[0].data())
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [userData, productData])

  useEffect(() => {
    if (productAnalysisId && brandDataId && productId) {
      setIsLoading(true)
      const docRef = doc(
        db,
        'brands',
        brandDataId,
        'products',
        productId,
        'analysis',
        productAnalysisId
      )

      // Real-time listener for the document
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const data: IAnalysisData = docSnap.data() as IAnalysisData
            setAnalysisData(data)
          } else {
            console.error('No such document!')
          }
          setIsLoading(false)
        },
        (error) => {
          console.error('Error fetching document:', error)
          setIsLoading(false)
        }
      )

      // Cleanup the listener on component unmount
      return () => unsubscribe()
    }
  }, [productAnalysisId, userData, productData])

  useEffect(() => {
    const getProductAnalysisId = async () => {
      if (brandDataId && productId && !productAnalysisId) {
        const analysisDocRef = collection(
          db,
          'brands',
          brandDataId,
          'products',
          productId,
          'analysis'
        )
        const analysisSnapshot = await getDocs(analysisDocRef)
        if (!analysisSnapshot.empty) {
          const firstDoc = analysisSnapshot.docs[0]

          setProductAnalysisId(firstDoc.id)
        }
      }
    }

    getProductAnalysisId()
  }, [productData])

  const handleGenerateAnalysisData = useCallback(async (): Promise<void> => {
    try {
      setIsMiniLoader(true)
      const user = auth.currentUser
      const idToken = await user?.getIdToken()
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/sendMessageToTopic`,
        {
          topic: 'topic-product-generate-analysis',
          message: {
            brandId: brandId,
            productId: productId
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`
          }
        }
      )

      if (response.status && brandDataId && productId) {
        const docRef = doc(db, 'brands', brandDataId, 'products', productId)

        await updateDoc(docRef, {
          status: 'pending'
        })
      }
    } catch (error) {
      messageApi.open({
        type: 'error',
        content:
          'Something went wrong while generating the analysis. Please try again later.',
        onClick: () => {
          messageApi.destroy()
        }
      })
      console.error(error)
    } finally {
      setIsMiniLoader(false)
    }
  }, [])

  const getTabContent = (tab: dataTabs, data: IProductData) => {
    switch (tab) {
      case 'dashboard':
        return <ProductDashboard productData={data} />
      case 'profile':
        return (
          <ConsumerProfile
            handleGenerateAnalysisData={() => handleGenerateAnalysisData()}
            isMiniLoader={isMiniLoader}
            isLoadingData={isLoading}
            consumerProfileData={consumerProfileData}
            reportStatus={productData?.status}
            reportMonth={productData?.report_month}
            reportDate={productData?.report_date}
          />
        )
      case 'analysis':
        return (
          <AnalysisTab
            analysisData={analysisData}
            isMiniLoader={isMiniLoader}
            handleGenerateAnalysisData={() => handleGenerateAnalysisData()}
            reportStatus={productData?.status}
            reportMonth={productData?.report_month}
            reportDate={productData?.report_date}
          />
        )
      case 'alerts':
        return (
          <AlertsTab
            productAlertsData={productAlertsData}
            isLoading={isLoading}
          />
        )
      default:
        return ''
    }
  }

  const tabPanel = () => {
    return (
      <Radio.Group
        defaultValue="dashboard"
        className="flex justify-center max-sm:flex-col [&_.ant-radio-button-wrapper]:h-12 [&_.ant-radio-button-wrapper]:flex [&_.ant-radio-button-wrapper]:items-center [&_.ant-radio-button-wrapper]:justify-center [&_.ant-radio-button-wrapper]:px-5  [&_.ant-radio-button-wrapper]:bg-cons-blue/5 [&_.ant-radio-button-wrapper-checked]:bg-cons-blue [&_.ant-radio-button-wrapper-checked]:text-white ![&_.ant-radio-button-wrapper-checked]:bg-green-700"
        optionType="button"
        buttonStyle="solid"
        onChange={(event) => handleTabChange(event.target.value)}
      >
        <Radio.Button value="dashboard">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'dashboard' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-dashboard mr-3 h-6">
              {activeTab === 'dashboard' ? (
                <DashboardWhiteIcon />
              ) : (
                <DashboardBlueIcon />
              )}
            </i>
            Dashboard
          </div>
        </Radio.Button>
        <Radio.Button value="alerts">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'alerts' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-dashboard mr-3 h-6">
              <ExclamationBlueIcon
                width="22px"
                height="22px"
                fill={`${activeTab === 'alerts' ? 'white' : '#2F5497'}`}
              />
            </i>
            Alerts
          </div>
        </Radio.Button>
        <Radio.Button value="profile">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'profile' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-user mr-3 h-6">
              {activeTab === 'profile' ? (
                <ProfileWhiteIcon />
              ) : (
                <ProfileBlueIcon />
              )}
            </i>
            Consumer Profile
          </div>
        </Radio.Button>
        <Radio.Button value="analysis">
          <div
            className={`flex items-center justify-center text-base font-medium ${activeTab !== 'analysis' ? 'hover:text-cons-blue text-cons-blue' : ''}`}
          >
            <i className="icon-analysis mr-3 h-6">
              {activeTab === 'analysis' ? (
                <AnalysisWhiteIcon />
              ) : (
                <AnalysisBlueIcon />
              )}
            </i>
            Analysis & Recommendations
          </div>
        </Radio.Button>
      </Radio.Group>
    )
  }

  return (
    <div className="max-w-6xl w-4/5 mx-auto mb-12 relative">
      {!isLoading && <BackButton />}
      {productData ? (
        <>
          <MainDataCard cardData={productData} id={productId} />
          <div className="mt-6">{tabPanel()}</div>
          {productData && getTabContent(activeTab, productData)}
        </>
      ) : isLoading ? (
        <Spin className="w-full flex justify-center pt-[50px]" />
      ) : (
        <div className="font-semibold text-center text-[larger] mt-[60px]">
          Product Not Fount
        </div>
      )}
      {contextHolder}
    </div>
  )
}

export default ProductDetails
