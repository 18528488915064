import React from 'react'
import { useParams, Navigate } from 'react-router-dom'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import DefaultEmptyCard from '../defaultEmptyCard/DefaultEmptyCard' // Assuming this provides userData

interface BrandAccessRouteProps {
  children: React.ReactNode
}

const BrandAccessRoute: React.FC<BrandAccessRouteProps> = ({ children }) => {
  const { brandId } = useParams()
  const { userData } = useUser()

  // Check if the user has access to the brandId
  const isAccess = brandId && userData?.brand_ids?.includes(brandId)

  if (!isAccess) {
    // Redirect to a "not authorized" page or login page
    // return <Navigate to="/not-authorized" replace />;
    return (
      <div className="max-w-6xl w-4/5 mx-auto mt-8 mb-10 overflow-hidden">
        <DefaultEmptyCard titleKey="brand" />
      </div>
    )
  }

  return <>{children}</>
}

export default BrandAccessRoute
