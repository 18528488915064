import React, { useCallback, useEffect, useState } from 'react'
import { db } from '../../firebase/firebase'
import {
  collection,
  query,
  limit,
  startAfter,
  getDocs,
  orderBy,
  where,
  Query,
  DocumentData,
  getCountFromServer
} from 'firebase/firestore'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import { Table, Button, TablePaginationConfig, Input, Tooltip } from 'antd'
import { IProductData } from '../brand/brand.interface'
import { getProductListConfig } from './product-list-config'
import { formattedNumber } from '../../helpers/helperFunctions'
import { FilterValue } from 'antd/es/table/interface'
import { useNavigate, useParams } from 'react-router-dom'
import CheckboxGroup from '../../components/checkboxGroup/CheckboxGroup'
import { SearchOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import BackButton from '../../components/shared/backButton/BackButton'
export interface SortingParameter {
  columnKey: string
  order: 'ascend' | 'descend' | null
}

const defaultSortingParams: SortingParameter = {
  columnKey: 'number-reviews',
  order: 'descend'
}

const getSortField = (type?: string) => {
  switch (type) {
    case 'item-id':
      return 'key'
    case 'name':
      return 'name'
    case 'category':
      return 'category'
    case 'consumerly-index':
      return 'stats.consumerly.index.current'
    case 'number-reviews':
      return 'stats.consumerly.total_reviews.current'
    default:
      return '__name__'
  }
}

const ProductList = () => {
  const { userData } = useUser()
  const pageSize = 10
  const navigate = useNavigate()
  const { brandId } = useParams()
  const brandDataId = userData?.brand_id || brandId

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [productsData, setProductsData] = useState<IProductData[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalProducts, setTotalProducts] = useState(0)
  const [totalFilteredProducts, setTotalFilteredProducts] = useState(0)
  const [sortingParameters, setSortingParameters] =
    useState<SortingParameter>(defaultSortingParams)
  const [productSearchValue, setProductSearchValue] = useState('')
  const [statusFilter, setStatusFilter] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')

  const handleCheckboxChange = (statusList: string[]) => {
    setStatusFilter(statusList)
    setCurrentPage(1)
    // Update URL with new statuses
    const params = new URLSearchParams()
    if (statusList.length > 0) {
      params.set('review_status', statusList.join(','))
    }
    navigate(`${location.pathname}?${params.toString()}`, { replace: true })
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const statuses = params.get('review_status')
    const parsedStatuses = statuses ? statuses.split(',') : []
    setStatusFilter(parsedStatuses)

    // Load the first page initially
    !productSearchValue &&
      brandDataId &&
      fetchProducts(brandDataId, parsedStatuses)
  }, [
    currentPage,
    sortingParameters,
    userData,
    location.search,
    productSearchValue
  ])

  const debounceSearch = useCallback(
    debounce((value: string) => {
      onSearch(value)
    }, 500),
    []
  )

  const handleSearch = (e: any) => {
    const value = e?.target?.value
    setSearchTerm(value)
    debounceSearch(value)
  }

  const fetchProductById = async (
    brandId: string,
    itemId: string
  ): Promise<void> => {
    setIsLoading(true)
    try {
      if (brandId && itemId) {
        const productsRef = collection(db, 'brands', brandId, 'products')

        // Query for the product with the exact item_id
        const q = query(productsRef, where('item_id', '==', itemId))
        const querySnapshot = await getDocs(q)

        const fetchedProduct = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          key: doc.id
        }))

        // @ts-ignore
        setProductsData(fetchedProduct.length > 0 ? fetchedProduct : [])
        setTotalFilteredProducts(fetchedProduct.length)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onSearch = (value: string) => {
    setProductSearchValue(value)
    setCurrentPage(1)
    if (brandDataId) {
      fetchProductById(brandDataId, value)
    }
  }

  const fetchProducts: Function = useCallback(
    async (brandId?: string, reviewStatusFilter?: string[]): Promise<void> => {
      setIsLoading(true)
      try {
        if (brandId) {
          const productsRef = collection(db, 'brands', brandId, 'products')
          const offset = (currentPage - 1) * pageSize

          const sortOrderValue: 'asc' | 'desc' | undefined =
            sortingParameters?.order === 'ascend'
              ? 'asc'
              : sortingParameters?.order === 'descend'
                ? 'desc'
                : undefined

          let q: Query<DocumentData> = productsRef
          let filteredQuery: Query<DocumentData> = productsRef

          filteredQuery = query(
            productsRef,
            orderBy(getSortField(sortingParameters?.columnKey), sortOrderValue)
          )

          if (reviewStatusFilter?.length) {
            q = query(
              q,
              where('review_status', 'array-contains-any', reviewStatusFilter)
            )
            filteredQuery = query(
              filteredQuery,
              where('review_status', 'array-contains-any', reviewStatusFilter)
            )
          }

          if (offset > 0) {
            const lastDocSnapshot = await getLastDocForPage(
              q,
              offset,
              getSortField(sortingParameters?.columnKey),
              sortOrderValue
            )

            if (lastDocSnapshot) {
              q = query(
                q,
                orderBy(
                  getSortField(sortingParameters?.columnKey),
                  sortOrderValue
                ),
                limit(pageSize),
                startAfter(lastDocSnapshot)
              )
            }
          } else {
            q = query(
              q,
              orderBy(
                getSortField(sortingParameters?.columnKey),
                sortOrderValue
              )
            )
            q = query(q, limit(pageSize))
          }

          const querySnapshot = await getDocs(q)

          const filteredQuerySnapshot = await getCountFromServer(filteredQuery)
          setTotalFilteredProducts(filteredQuerySnapshot.data().count)
          if (!totalProducts) {
            const totalSnapshot = await getCountFromServer(productsRef)
            setTotalProducts(totalSnapshot.data().count)
          }
          const fetchedProducts = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            key: doc.id // `key` is required for Ant Design Table
          }))
          // @ts-ignore
          setProductsData(fetchedProducts)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    },
    [currentPage, sortingParameters]
  )

  // Helper function to find the last document of the previous page
  const getLastDocForPage = async (
    productsRef: any,
    offset: number,
    orderField: string | undefined,
    orderValue: 'asc' | 'desc' | undefined
  ) => {
    const q = query(
      productsRef,
      orderBy(orderField || '__name__', orderValue),
      limit(offset)
    )
    const snapshot = await getDocs(q)
    return snapshot.docs[snapshot.docs.length - 1]
  }

  const rowSelection = {
    columnWidth: '10px',
    selectedRowKeys,
    onChange: (newSelectedRowKeys: string[]) =>
      setSelectedRowKeys(newSelectedRowKeys)
  }

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    if (sorter.order) {
      setProductSearchValue('')
      sorter.order !== sortingParameters.order && setCurrentPage(1)
    }
    setSortingParameters({
      columnKey: sorter.order ? sorter.columnKey : '__name__',
      order: sorter.order
    })
  }

  const filterOptions = [
    { label: 'Poor Reviews', value: 'poor_reviews' },
    { label: 'Critical Issues', value: 'critical_issues' },
    { label: 'Too Few Reviews', value: 'too_few_reviews' }
  ]

  return (
    <div className="max-w-6xl w-4/5 mx-auto mt-8 mb-10 overflow-hidden">
      {<BackButton />}
      <div className="flex justify-between">
        <div className="">
          <div className="">
            <div className="my-2 text-xl font-semibold">All brand products</div>
          </div>
          <Tooltip title="Enter full ASIN">
            <Input
              className="bg-transparent text-blue font-medium placeholder-gray-400 focus:outline-none w-60 h-10"
              placeholder="ASIN"
              prefix={<SearchOutlined className="text-cons-blue mr-1" />}
              value={searchTerm}
              onChange={handleSearch}
              allowClear
            />
          </Tooltip>
        </div>
        <div className="mt-4 text-right text-cons-blue">
          Total number of products: {formattedNumber(totalProducts)}
          <Button
            disabled={true}
            className="text-cons-gray-step-up font-semibold ml-4 border-none"
            type="primary"
          >
            Group Details
          </Button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-4 mt-4 items-baseline">
        <label className="text-md font-medium mb-2 sm:mb-0 sm:mr-2">
          Filter by:
        </label>
        <CheckboxGroup
          options={filterOptions}
          selectedOptions={statusFilter}
          onChange={(e) => handleCheckboxChange(e)}
          disabled={!!searchTerm}
        />
      </div>
      <div className="mt-4 shadow-[1px_2px_5px_1px_rgba(29,42,68,0.12)]">
        {/* TODO use DataTable component */}
        <Table
          loading={isLoading || !userData}
          // @ts-ignore
          rowSelection={rowSelection}
          columns={getProductListConfig(searchTerm, sortingParameters, brandId)}
          dataSource={productsData}
          rowKey="key"
          rootClassName="[&_th]:text-cons-blue"
          className="[&_th]:text-cons-blue border border-solid border-cons-gray-step-down rounded-lg"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            showSizeChanger: false,
            total: totalFilteredProducts,
            onChange: (page: number) => setCurrentPage(page)
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  )
}

export default ProductList
