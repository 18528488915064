import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  getFirestore,
  doc,
  onSnapshot,
  getDocs,
  getDoc,
  collection,
  updateDoc
} from 'firebase/firestore'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import { Button, message } from 'antd'
import { ReactComponent as AnalysisIcon } from '../../icons/analysis.svg'
import { auth, db } from '../../firebase/firebase'
import axios from 'axios'
import { ReactComponent as AILoader } from '../../icons/ai-work.svg'
import ProductContext, {
  ProductContextValue
} from '../../contexts/ProductProvider/ProductProvider'
import { formatYearMonth } from '../../helpers/helperFunctions'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

interface CategoryComparisonProps {
  categoryId: string
  brandId: string
  productId: string
  customerSentimentId: string
}
interface IProductComparisonData {
  best_product_comparison: string
  positive_negative_standout: string
  improvement_to_top: string
}

const CategoryComparison = ({
  categoryId,
  brandId,
  productId,
  customerSentimentId
}: CategoryComparisonProps) => {
  const location = useLocation()
  const { state } = location
  const { userData } = useUser()
  const brandDataId = userData?.brand_id || brandId
  const [messageApi, contextHolder] = message.useMessage()

  // @ts-ignore
  const productData: ProductContextValue | null = useContext(ProductContext)

  const [isCategoryVerified, setIsCategoryVerified] = useState(false)
  const [isBestProductComparisonExpanded, setIsBestProductComparisonExpanded] =
    useState<boolean>(false)
  const [isImprovementToTopExpanded, setIsImprovementToTopExpanded] =
    useState<boolean>(false)
  const [
    isPositiveNegativeStandoutExpanded,
    setIsPositiveNegativeStandoutExpanded
  ] = useState<boolean>(false)

  const [productComparisonData, setProductComparisonData] =
    useState<IProductComparisonData | null>(null)
  const [isMiniLoader, setIsMiniLoader] = useState<boolean>(false)
  const [isDataEmpty, setIsDataEmpty] = useState(false)

  useEffect(() => {
    if (!productId || !customerSentimentId || !brandId || !categoryId) {
      return
    }

    const db = getFirestore()

    const docRef = collection(
      db,
      'brands',
      brandId,
      'products',
      productId,
      'customerSentiments',
      customerSentimentId,
      'productComparison'
    )

    const checkDocumentExists = async () => {
      try {
        const querySnapshot = await getDocs(docRef)

        if (!querySnapshot.empty) {
          const document = querySnapshot.docs[0]

          const docRef2 = doc(
            db,
            'brands',
            brandId,
            'products',
            productId,
            'customerSentiments',
            customerSentimentId,
            'productComparison',
            document.id
          )
          // Subscribe to document if it exists
          onSnapshot(docRef2, (docSnap) => {
            if (!querySnapshot.empty) {
              const updatedData = docSnap.data() as IProductComparisonData
              setProductComparisonData(updatedData)
              setIsDataEmpty(false)
            }
          })
        } else {
          setIsDataEmpty(true)
        }
      } catch (error) {
        console.error('Error checking document:', error)
      }
    }

    checkDocumentExists()
  }, [productId, customerSentimentId, brandId, categoryId, productData?.status])

  useEffect(() => {
    const checkCategoryExists = async () => {
      if (!categoryId) {
        console.warn('Category ID is not available in context.')
        return
      }

      try {
        const categoryRef = doc(db, 'categories', categoryId)
        const categorySnap = await getDoc(categoryRef)
        if (categorySnap.exists()) {
          setIsCategoryVerified(true) // Document exists
        }
      } catch (error) {
        console.error(error)
      }
    }

    checkCategoryExists()
  }, [state.category_id])

  const handleCreateAnalysisRequest = async () => {
    if (!productId || !customerSentimentId) {
      return
    }
    if (brandDataId && productId && customerSentimentId) {
      try {
        setIsMiniLoader(true)
        const user = auth.currentUser
        const idToken = await user?.getIdToken()
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/sendMessageToTopic`,
          {
            topic: 'topic-product-generate-analysis',
            message: {
              brandId: brandId,
              productId: productId
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`
            }
          }
        )
        if (response.status) {
          const docRef = doc(db, 'brands', brandDataId, 'products', productId)

          // Update status to "pending"
          await updateDoc(docRef, {
            status: 'pending'
          })
        }
      } catch (error) {
        messageApi.open({
          type: 'error',
          content:
            'Something went wrong while generating the analysis. Please try again later.',
          onClick: () => {
            messageApi.destroy()
          }
        })
        console.error(error)
      } finally {
        setIsMiniLoader(false)
      }
    }
  }

  return (
    <div>
      <h2>Product Comparison</h2>
      <div className="mt-4 bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
        {userData?.category_ids?.includes(state.category_id) &&
        isCategoryVerified ? (
          <div>
            {productData?.status === 'pending' && (
              <div className="grid min-h-[300px] place-items-center">
                <p className="text-center font-semibold text-lg">
                  Please wait while our AI analysis is being prepared. <br />
                  You can come back to this page later.
                </p>
                <AILoader width="200px" height="200px" />
              </div>
            )}
            {productComparisonData && productData?.status === 'completed' && (
              <div className="space-y-4">
                {productComparisonData.best_product_comparison && (
                  <div className="rounded-lg p-4 mb-4">
                    <div className="flex justify-between">
                      <h2 className="font-bold text-xl mb-2 text-cons-blue">
                        How does it compare to the best product in the category?
                      </h2>
                    </div>

                    <div>
                      <div className="relative overflow-hidden">
                        <div
                          className={`text-box overflow-hidden transition-all duration-300 relative ${isBestProductComparisonExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                          style={
                            isBestProductComparisonExpanded
                              ? { height: 'auto' }
                              : { maxHeight: '160px' }
                          }
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                          >
                            {productComparisonData.best_product_comparison}
                          </ReactMarkdown>
                        </div>
                      </div>
                      <button
                        onClick={() =>
                          setIsBestProductComparisonExpanded(
                            !isBestProductComparisonExpanded
                          )
                        }
                        className="text-cons-blue font-medium ml-8"
                      >
                        {isBestProductComparisonExpanded
                          ? 'Read Less'
                          : 'Read More'}
                      </button>
                    </div>
                  </div>
                )}
                {productComparisonData.positive_negative_standout && (
                  <div className="rounded-lg p-4 mb-4">
                    <div className="flex justify-between">
                      <h2 className="font-bold text-xl mb-2 text-cons-blue">
                        What does make it positively/negatively stand out form
                        the category?
                      </h2>
                    </div>

                    <div>
                      <div className="relative overflow-hidden">
                        <div
                          className={`text-box overflow-hidden transition-all duration-300 relative ${isPositiveNegativeStandoutExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                          style={
                            isPositiveNegativeStandoutExpanded
                              ? { height: 'auto' }
                              : { maxHeight: '160px' }
                          }
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                          >
                            {productComparisonData.positive_negative_standout}
                          </ReactMarkdown>
                        </div>
                      </div>
                      <button
                        onClick={() =>
                          setIsPositiveNegativeStandoutExpanded(
                            !isPositiveNegativeStandoutExpanded
                          )
                        }
                        className="text-cons-blue font-medium ml-8"
                      >
                        {isPositiveNegativeStandoutExpanded
                          ? 'Read Less'
                          : 'Read More'}
                      </button>
                    </div>
                  </div>
                )}

                {productComparisonData.improvement_to_top && (
                  <div className="rounded-lg p-4 mb-4">
                    <div className="flex justify-between">
                      <h2 className="font-bold text-xl mb-2 text-cons-blue">
                        What needs to be improved so the product scores at the
                        top of the category.
                      </h2>
                    </div>

                    <div>
                      <div className="relative overflow-hidden">
                        <div
                          className={`text-box overflow-hidden transition-all duration-300 relative ${isImprovementToTopExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                          style={
                            isImprovementToTopExpanded
                              ? { height: 'auto' }
                              : { maxHeight: '160px' }
                          }
                        >
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                          >
                            {productComparisonData.improvement_to_top}
                          </ReactMarkdown>
                        </div>
                      </div>
                      <button
                        onClick={() =>
                          setIsImprovementToTopExpanded(
                            !isImprovementToTopExpanded
                          )
                        }
                        className="text-cons-blue font-medium ml-8"
                      >
                        {isImprovementToTopExpanded ? 'Read Less' : 'Read More'}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {isDataEmpty && (
              <div className="text-center mt-4 font-semibold">No Data</div>
            )}

            {productData &&
              productData?.status !== 'pending' &&
              productData?.report_month !==
                formatYearMonth(productData.report_date) && (
                <div className="flex flex-col sm:flex-row px-8">
                  <div className="p-3 w-6/12 max-sm:w-full text-center">
                    <AnalysisIcon className="w-2/3 " />
                  </div>
                  <div className="ml-4 p-6 mb-4 w-6/12 max-sm:w-full flex items-center">
                    <div>
                      <h3 className="mb-4 font-semibold">
                        Would you like to start analyzing this product now?
                      </h3>
                      <div className="mb-4">
                        After activating this product, you will get unlimited
                        access to the Analysis & Recommendations for this month.
                      </div>
                      <Button
                        type="primary"
                        className="bg-cons-blue hover:bg-cons-blue-step-down h-10"
                        onClick={() => handleCreateAnalysisRequest()}
                        loading={isMiniLoader}
                      >
                        Generate analysis
                      </Button>
                    </div>
                  </div>
                </div>
              )}
          </div>
        ) : (
          <div className="p-6">
            <p className="text-center font-semibold text-lg">
              Category analysis is not licensed for this category. Please
              contact&nbsp;
              <a
                href="mailto:support@consumerly.ai"
                className="text-cons-blue underline"
              >
                support@consumerly.ai
              </a>
              &nbsp;to enable it.
            </p>
          </div>
        )}
      </div>
      {contextHolder}
    </div>
  )
}

export default CategoryComparison
