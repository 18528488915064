import React, { useContext, useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import {
  getFirestore,
  doc,
  onSnapshot,
  getDoc,
  updateDoc
} from 'firebase/firestore'
import { useUser } from '../../contexts/UserProvider/UserProvider'
import BackButton from '../../components/shared/backButton/BackButton'
import { Button, message, Spin } from 'antd'
import { ISentimentOverview } from '../productDetails/product.interface'
import { ReactComponent as AnalysisIcon } from '../../icons/analysis.svg'
import SentimentCard from '../../components/sentimentCard/SentimentCard'
import Chart from '../../components/Chart/Chart'
import { auth, db } from '../../firebase/firebase'
import axios from 'axios'
import { ReactComponent as AILoader } from '../../icons/ai-work.svg'
import CategoryComparison from '../../components/categoryComparison/CategoryComparison'
import { formatYearMonth } from '../../helpers/helperFunctions'
import ProductContext, {
  ProductContextValue
} from '../../contexts/ProductProvider/ProductProvider'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

const SentimentOverview = () => {
  const location = useLocation()
  const { state } = location
  const { userData } = useUser()
  const { productId, customerSentimentId, brandId } = useParams()
  const brandDataId = userData?.brand_id || brandId
  const [messageApi, contextHolder] = message.useMessage()
  const productData: ProductContextValue | null = useContext(ProductContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isGenerateLoading, setIsGenerateLoading] = useState<boolean>(false)
  const [isSummaryExpanded, setIsSummaryExpanded] = useState<boolean>(false)
  const [isPositiveSummaryExpanded, setIsPositiveSummaryExpanded] =
    useState<boolean>(false)
  const [isNegativeSummaryExpanded, setIsNegativeSummaryExpanded] =
    useState<boolean>(false)
  const [isRecommendationsExpanded, setIsRecommendationsExpanded] =
    useState<boolean>(false)
  // const [summaryData, setSummaryData] = useState<any>(undefined)
  const [overviewData, setOverviewData] = useState<ISentimentOverview | null>(
    null
  )
  const [isMiniLoader, setIsMiniLoader] = useState<boolean>(false)

  useEffect(() => {
    const db = getFirestore()

    if (customerSentimentId && productId && brandDataId) {
      setIsLoading(true)
      const docRef = doc(
        db,
        'brands',
        brandDataId,
        'products',
        productId,
        'customerSentiments',
        customerSentimentId
      )

      // Real-time listener for the document
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const data: ISentimentOverview =
              docSnap.data() as ISentimentOverview
            setOverviewData(data)
          } else {
            console.error('No such document!')
          }
          setIsLoading(false)
        },
        (error) => {
          console.error('Error fetching document:', error)
          setIsLoading(false)
        }
      )

      // Cleanup the listener on component unmount
      return () => unsubscribe()
    }
  }, [productId, customerSentimentId, userData])

  useEffect(() => {
    if (productData?.status === 'pending') {
      setIsGenerateLoading(true)
    }

    if (
      (!productData?.status || productData?.status === 'completed') &&
      overviewData?.summary_overview &&
      (overviewData.summary_overview.summary ||
        overviewData.summary_overview.negative_summary ||
        overviewData.summary_overview.positive_summary ||
        overviewData.summary_overview.recommendations)
    ) {
      setIsGenerateLoading(false)
    }
  }, [productData, overviewData])

  const handleCreateAnalysisRequest = async () => {
    if (!productId || !customerSentimentId) {
      return
    }
    if (brandDataId && productId && customerSentimentId) {
      try {
        setIsMiniLoader(true)
        const user = auth.currentUser
        const idToken = await user?.getIdToken()
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/sendMessageToTopic`,
          {
            topic: 'topic-product-generate-analysis',
            message: {
              brandId: brandId,
              productId: productId
            }
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`
            }
          }
        )
        if (response.status) {
          const docRef = doc(db, 'brands', brandDataId, 'products', productId)

          // Update status to "pending"
          await updateDoc(docRef, {
            status: 'pending'
          })
        }
      } catch (error) {
        messageApi.open({
          type: 'error',
          content:
            'Something went wrong while generating the analysis. Please try again later.',
          onClick: () => {
            messageApi.destroy()
          }
        })
        console.error(error)
      } finally {
        setIsMiniLoader(false)
      }
    }
  }

  return (
    <div className="max-w-6xl w-4/5 mx-auto mt-8 mb-12 relative">
      {<BackButton />}
      {isLoading && !productData ? (
        <div className="grid min-h-[300px] place-items-center">
          <Spin size="large" />
        </div>
      ) : overviewData ? (
        <div className="mb-8">
          <div className="pt-2">
            {overviewData.title ? <>{overviewData.title}</> : 'Overview'}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
            <SentimentCard overviewData={overviewData} />
            <div className="relative p-6 border rounded-lg shadow-lg bg-white">
              <div className="flex justify-between text-sm">
                {overviewData.description && (
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {overviewData.description}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          </div>
          {overviewData.sentiment_history_chart && (
            <div className="mt-6">
              <div className="mb-4">Your Sentiment History</div>
              <Chart dataChart={overviewData.sentiment_history_chart} />
            </div>
          )}
          <div className="mt-8 bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
            {isGenerateLoading && (
              <div className="grid min-h-[300px] place-items-center">
                <p className="text-center font-semibold text-lg">
                  Please wait while our AI analysis is being prepared. <br />
                  You can come back to this page later.
                </p>
                <AILoader width="200px" height="200px" />
              </div>
            )}
            {overviewData.summary_overview &&
              productData?.status === 'completed' && (
                <div className="space-y-4">
                  {overviewData.summary_overview.summary && (
                    <div className="rounded-lg p-4 mb-4">
                      <div className="flex justify-between">
                        <h2 className="font-bold text-xl mb-2 text-cons-blue">
                          Summary
                        </h2>
                      </div>

                      <div>
                        <div className="relative overflow-hidden">
                          <div
                            className={`text-box overflow-hidden transition-all duration-300 relative ${isSummaryExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                            style={
                              isSummaryExpanded
                                ? { height: 'auto' }
                                : { maxHeight: '160px' }
                            }
                          >
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                            >
                              {overviewData.summary_overview.summary}
                            </ReactMarkdown>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setIsSummaryExpanded(!isSummaryExpanded)
                          }
                          className="text-cons-blue font-medium ml-8"
                        >
                          {isSummaryExpanded ? 'Read Less' : 'Read More'}
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col sm:flex-row">
                    {overviewData.summary_overview?.positive_summary && (
                      <div className="rounded-lg p-4 mb-4 w-6/12 max-sm:w-full">
                        <div className="flex justify-between">
                          <h2 className="font-bold text-xl mb-2 text-cons-blue">
                            Positive Summary
                          </h2>
                        </div>

                        <div>
                          <div className="relative overflow-hidden">
                            <div
                              className={`text-box overflow-hidden transition-all duration-300 relative ${isPositiveSummaryExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                              style={
                                isPositiveSummaryExpanded
                                  ? { height: 'auto' }
                                  : { maxHeight: '160px' }
                              }
                            >
                              <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                              >
                                {overviewData.summary_overview.positive_summary}
                              </ReactMarkdown>
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              setIsPositiveSummaryExpanded(
                                !isPositiveSummaryExpanded
                              )
                            }
                            className="text-cons-blue font-medium ml-8"
                          >
                            {isPositiveSummaryExpanded
                              ? 'Read Less'
                              : 'Read More'}
                          </button>
                        </div>
                      </div>
                    )}
                    {overviewData.summary_overview?.negative_summary && (
                      <div className="rounded-lg p-4 mb-4 w-6/12 max-sm:w-full">
                        <div className="flex justify-between">
                          <h2 className="font-bold text-xl mb-2 text-cons-blue">
                            Negative Summary
                          </h2>
                        </div>

                        <div className="">
                          <div className="relative overflow-hidden">
                            <div
                              className={`text-box overflow-hidden transition-all duration-300 relative ${isNegativeSummaryExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                              style={
                                isNegativeSummaryExpanded
                                  ? { height: 'auto' }
                                  : { maxHeight: '160px' }
                              }
                            >
                              <ReactMarkdown
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                              >
                                {overviewData.summary_overview.negative_summary}
                              </ReactMarkdown>
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              setIsNegativeSummaryExpanded(
                                !isNegativeSummaryExpanded
                              )
                            }
                            className="text-cons-blue font-medium ml-8"
                          >
                            {isNegativeSummaryExpanded
                              ? 'Read Less'
                              : 'Read More'}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {overviewData.summary_overview?.recommendations && (
                    <div className="rounded-lg p-4 mb-4">
                      <div className="flex justify-between">
                        <h2 className="font-bold text-xl mb-2 text-cons-blue">
                          Recommendations
                        </h2>
                      </div>

                      <div>
                        <div className="relative overflow-hidden">
                          <div
                            className={`text-box overflow-hidden transition-all duration-300 relative ${isRecommendationsExpanded ? 'max-h-full' : 'shadow-b max-h-60'}`}
                            style={
                              isRecommendationsExpanded
                                ? { height: 'auto' }
                                : { maxHeight: '160px' }
                            }
                          >
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                            >
                              {overviewData.summary_overview.recommendations}
                            </ReactMarkdown>
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            setIsRecommendationsExpanded(
                              !isRecommendationsExpanded
                            )
                          }
                          className="text-cons-blue font-medium ml-8"
                        >
                          {isRecommendationsExpanded
                            ? 'Read Less'
                            : 'Read More'}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            {productData &&
              productData?.status !== 'pending' &&
              productData?.report_month !==
                formatYearMonth(productData.report_date) && (
                <div className="flex flex-col sm:flex-row px-8">
                  <div className="p-3 w-6/12 max-sm:w-full text-center">
                    <AnalysisIcon className="w-2/3 " />
                  </div>
                  <div className="ml-4 p-6 mb-4 w-6/12 max-sm:w-full flex items-center">
                    <div>
                      <h3 className="mb-4 font-semibold">
                        Would you like to start analyzing this product now?
                      </h3>
                      <div className="mb-4">
                        After activating this product, you will get unlimited
                        access to the Analysis & Recommendations for this month.
                      </div>
                      <Button
                        type="primary"
                        className="bg-cons-blue hover:bg-cons-blue-step-down h-10"
                        onClick={() => handleCreateAnalysisRequest()}
                        // onClick={() => handleClick()}
                        loading={isMiniLoader}
                      >
                        Generate analysis
                      </Button>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : (
        <div className="font-bold text-center">
          No cusotmer sentiments overivew for this product
        </div>
      )}
      {!isLoading &&
        brandDataId &&
        productId &&
        customerSentimentId &&
        productData && (
          <CategoryComparison
            categoryId={state?.category_id}
            brandId={brandDataId}
            productId={productId}
            customerSentimentId={customerSentimentId}
          />
        )}
      {contextHolder}
    </div>
  )
}

export default SentimentOverview
