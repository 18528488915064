import React, { useState } from 'react'
import { Button } from 'antd'
import { IAnalysisData, IDataBlock } from '../../product.interface'
import { ReactComponent as AnalysisIcon } from '../../../../icons/analysis.svg'
import './Analysis.scss'
import { ReactComponent as AILoader } from '../../../../icons/ai-work.svg'
import { formatYearMonth } from '../../../../helpers/helperFunctions'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'

interface AnalysisTabProps {
  handleGenerateAnalysisData: (() => void) | undefined
  analysisData?: IAnalysisData
  isMiniLoader?: boolean
  reportStatus?: 'pending' | 'completed' | 'none'
  reportMonth?: string
  reportDate?: { seconds: number; nanoseconds: number }
}

const ExpandableBlock = ({ title, markdownText }: any) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className="rounded-lg p-4 mb-4">
      <div className="flex justify-between">
        <h2 className="font-bold text-xl mb-2 text-cons-blue">{title}</h2>
      </div>
      <div>
        <div className="relative overflow-hidden">
          <div
            className={`text-box overflow-hidden transition-all duration-300 relative ${isExpanded ? 'max-h-full' : 'max-h-60'} ${isExpanded ? '' : 'shadow-b'}`}
            style={isExpanded ? { height: 'auto' } : { maxHeight: '160px' }}
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
              {markdownText}
            </ReactMarkdown>
          </div>
        </div>
        <button
          onClick={toggleExpand}
          className="text-cons-blue font-medium ml-8"
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      </div>
    </div>
  )
}

// Parent component for both blocks
const AnalysisTab = ({
  analysisData,
  handleGenerateAnalysisData,
  isMiniLoader,
  reportMonth,
  reportStatus,
  reportDate
}: AnalysisTabProps) => {
  const analysisTitles: string[] = [
    'New Item Ideas',
    'Recommendations to Improve the Item'
  ]

  return (
    <div className="container mx-auto p-4">
      <h2 className="my-2 mt-8 font-medium">See Analysis & Recommendations</h2>

      <div className="bg-cons-blue-step-down/25 rounded-2xl w-full overflow-hidden min-h-[400px]">
        {reportStatus === 'pending' && (
          <div className="grid min-h-[300px] place-items-center">
            <p className="text-center font-semibold text-lg">
              Please wait while our AI analysis is being prepared. <br />
              You can come back to this page later.
            </p>
            <AILoader width="200px" height="200px" />
          </div>
        )}

        {analysisData &&
          (analysisData.product_ideas?.full_text ||
            analysisData.recommendations?.full_text) &&
          reportStatus === 'completed' && (
            <div className="space-y-4">
              {Object.entries(analysisData)
                .filter(
                  ([_, value]) =>
                    typeof value === 'object' && 'sort_order' in value
                ) // Filter only IDataBlock objects
                .sort(([_, a], [__, b]) => {
                  const sortOrderA =
                    (a as IDataBlock).sort_order ?? Number.MAX_SAFE_INTEGER
                  const sortOrderB =
                    (b as IDataBlock).sort_order ?? Number.MAX_SAFE_INTEGER
                  return sortOrderA - sortOrderB
                }) // Sort by sort_order, undefined goes last
                .map(([key, value], index) => {
                  const dataBlock = value as IDataBlock
                  return (
                    <div key={key}>
                      <ExpandableBlock
                        key={key}
                        uniqueKey={key}
                        title={analysisTitles[index]}
                        markdownText={dataBlock.full_text}
                      />
                    </div>
                  )
                })}
            </div>
          )}
        {reportDate &&
          reportStatus !== 'pending' &&
          reportMonth !== formatYearMonth(reportDate) && (
            <div className="flex flex-col sm:flex-row px-8">
              <div className="p-3 w-6/12 max-sm:w-full text-center">
                <AnalysisIcon className="w-2/3 " />
              </div>
              <div className="ml-4 p-6 mb-4 w-6/12 max-sm:w-full flex items-center">
                <div>
                  <h3 className="mb-4 font-semibold">
                    Would you like to start analyzing this product now?
                  </h3>
                  <div className="mb-4">
                    After activating this product, you will get unlimited access
                    to the Analysis & Recommendations for this month.
                  </div>
                  <Button
                    type="primary"
                    className="bg-cons-blue hover:bg-cons-blue-step-down h-10"
                    onClick={() =>
                      handleGenerateAnalysisData && handleGenerateAnalysisData()
                    }
                    loading={isMiniLoader}
                  >
                    Generate analysis
                  </Button>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  )
}

export default AnalysisTab
