import React from 'react'
import { TableProps } from 'antd'
import { IProductData } from '../brand/brand.interface'
import { formattedNumber } from '../../helpers/helperFunctions'
import { SortingParameter } from './ProductList'
import { Link } from 'react-router-dom'

export function getProductListConfig(
  productSearchValue: string,
  sortingParameters: SortingParameter,
  brandId?: string
): TableProps<IProductData>['columns'] {
  return [
    {
      title: 'Item ID',
      dataIndex: 'key',
      width: 30,
      ellipsis: true,
      key: 'item-id',
      className: 'font-semibold text-cons-blue-step-up',
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Title',
      dataIndex: 'name',
      width: 40,
      key: 'name',
      ellipsis: true,
      className: 'font-medium text-cons-gray',
      sorter: !productSearchValue,
      sortOrder:
        !productSearchValue && sortingParameters.columnKey === 'name'
          ? sortingParameters.order
          : null,
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Category',
      width: 20,
      dataIndex: 'category',
      key: 'category',
      ellipsis: true,
      className: 'font-medium text-[#003C5B]',
      sorter: !productSearchValue,
      sortOrder:
        !productSearchValue && sortingParameters.columnKey === 'category'
          ? sortingParameters.order
          : null,
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'Consumerly index',
      width: '25px',
      align: 'center',
      key: 'consumerly-index',
      dataIndex: ['stats', 'consumerly', 'index', 'current'],
      className: 'font-bold text-[#003C5B]',
      sorter: !productSearchValue,
      sortOrder:
        !productSearchValue &&
        sortingParameters.columnKey === 'consumerly-index'
          ? sortingParameters.order
          : null,
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: 'No. of reviews',
      width: '25px',
      align: 'center',
      key: 'number-reviews',
      dataIndex: ['stats', 'consumerly', 'total_reviews', 'current'],
      className: 'font-medium text-[#003C5B]',
      render: (value) => (value ? formattedNumber(value) : ''),
      sorter: !productSearchValue,
      sortOrder:
        !productSearchValue && sortingParameters.columnKey === 'number-reviews'
          ? sortingParameters.order
          : null,
      onHeaderCell: () => {
        return {
          style: {
            color: '#2F5497'
          }
        }
      }
    },
    {
      title: '',
      width: '20px',
      dataIndex: '',
      key: 'action',
      render: (data) => (
        <Link
          to={`/brand/${brandId}/product/${data.key}`}
          className="text-blue-500 underline"
        >
          See Details
        </Link>
      )
    }
  ]
}
