import React from 'react'
import Login from '../containers/login/Login'
import BrandList from '../containers/brandList/BrandList'
import Brand from '../containers/brand/Brand'
import ProductList from '../containers/productList/ProductList'
import NotFound from '../containers/notFound/NotFoundPage'
import ProtectedRoute from './ProtectedRoute'
import ProductDetails from '../containers/productDetails/ProductDetails'
import SentimentOverview from '../containers/sentimentOverview/SentimentOverview'
import CategoryList from '../containers/catogoryList/CategoryList'
import CategoryDetails from '../containers/categoryDetails/CategoryDetails'
import CategorySentimentOverview from '../containers/categoryDetails/categorySentimentOverview/CategorySentimentOverview'
import BrandAccessRoute from '../components/brandAccessRoute/BrandAccessRoute'
import { ProductProvider } from '../contexts/ProductProvider/ProductProvider'
import CategoryContext, {
  CategoryProvider
} from '../contexts/CategoryProvider/CategoryProvider'

export const routesArray = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/brand-list',
    element: (
      <ProtectedRoute>
        <BrandList />
      </ProtectedRoute>
    )
  },
  {
    path: '/brand/:brandId',
    element: (
      <ProtectedRoute>
        <BrandAccessRoute>
          <Brand />
        </BrandAccessRoute>
      </ProtectedRoute>
    )
  },
  {
    path: '/brand/:brandId/product-list',
    element: (
      <ProtectedRoute>
        <BrandAccessRoute>
          <ProductList />
        </BrandAccessRoute>
      </ProtectedRoute>
    )
  },
  {
    path: '/brand/:brandId/product/:productId',
    element: (
      <ProtectedRoute>
        <BrandAccessRoute>
          <ProductDetails />
        </BrandAccessRoute>
      </ProtectedRoute>
    )
  },
  {
    path: '/brand/:brandId/product/:productId/overview/:customerSentimentId',
    element: (
      <ProtectedRoute>
        <BrandAccessRoute>
          <ProductProvider>
            <SentimentOverview />
          </ProductProvider>
        </BrandAccessRoute>
      </ProtectedRoute>
    )
  },
  {
    path: '/category-insights',
    element: (
      <ProtectedRoute>
        <CategoryList />
      </ProtectedRoute>
    )
  },
  {
    path: '/category-details/:categoryId',
    element: (
      <ProtectedRoute>
        <CategoryProvider>
          <CategoryDetails />
        </CategoryProvider>
      </ProtectedRoute>
    )
  },
  {
    path: '/category-details/:categoryId/overview/:customerSentimentId',
    element: (
      <ProtectedRoute>
        <CategoryProvider>
          <CategorySentimentOverview />
        </CategoryProvider>
      </ProtectedRoute>
    )
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <BrandList />
      </ProtectedRoute>
    )
  },
  {
    path: '*',
    element: <NotFound />
  }
]
