import React, { useEffect, useState } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase/firebase'
import MainDataCard from '../../components/mainDataCard/MainDataCard'
import BrandEquity from '../../components/brandEquity/BrandEquity'
import Chart from '../../components/Chart/Chart'
import { Spin } from 'antd'
import {
  IUserContextData,
  useUser
} from '../../contexts/UserProvider/UserProvider'
import { IBrandData } from './brand.interface'
import { useParams } from 'react-router-dom'
import BackButton from '../../components/shared/backButton/BackButton'
const Brand = () => {
  const [brand, setBrand] = useState<IBrandData>()
  const { userData, setUserData } = useUser()
  const { brandId } = useParams() // Extracts 'id' from the URL
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (
      brandId &&
      userData?.brand_ids &&
      userData?.brand_ids?.length > 0 &&
      userData.brand_id !== brandId
    ) {
      // @ts-ignore
      setUserData((data: IUserContextData) => ({ ...data, brand_id: brandId }))
    }
  }, [brandId, userData])

  useEffect(() => {
    const fetchBrandData = async () => {
      if (brandId) {
        setIsLoading(true)
        try {
          const brandRef = doc(db, 'brands', brandId) // Get a reference to the document with the specific brandId
          const brandSnapshot = await getDoc(brandRef)
          if (brandSnapshot.exists()) {
            const brandData = { id: brandSnapshot.id, ...brandSnapshot.data() }
            //@ts-ignore
            setBrand(brandData) // You can set this to state if needed
          } else {
            console.log('No such brand exists')
            return null
          }
        } catch (error) {
          console.error('Error fetching brand:', error)
        } finally {
          setIsLoading(false)
        }
      }
    }
    const isAccess = brandId && userData?.brand_ids?.includes(brandId)

    isAccess && fetchBrandData()
  }, [userData, brandId])

  return (
    <div className="max-w-6xl w-4/5 mx-auto mb-12 relative">
      {brand ? (
        <>
          {userData?.brand_ids && userData?.brand_ids?.length > 1 && (
            <BackButton />
          )}

          <MainDataCard cardData={brand} />
          <div className="mt-6">
            <div className="mb-2">Brand Equity</div>
            <BrandEquity
              stats={brand.stats.equity}
              isCurrencySigns
              titles={[
                'Positive BrandList Equity',
                'Negative BrandList Equity',
                'Net BrandList Equity'
              ]}
            />
          </div>
          <div className="mt-6">
            <div className="mb-4">Brand Equity Over Time</div>
            <Chart dataChart={brand.stats.equity_chart} />
          </div>
          <div className="mt-6">
            <div className="mb-2">
              New Reviews
              {/*<span className="text-cons-gray-step-up-50 text-xs">*/}
              {/*  &nbsp; Current Week*/}
              {/*</span>*/}
            </div>
            <BrandEquity
              stats={brand.stats.latest_reviews}
              titles={[
                'Total Positive Reviews',
                'Total Neutral Reviews',
                'Total Negative Reviews'
              ]}
              isHideArrow
            />
          </div>
          {/* Hide aged reviews for now */}
          {/*<div className="mt-6">*/}
          {/*  <div className="mb-2">*/}
          {/*    Aged Reviews*/}
          {/*    <span className="text-cons-gray-step-up-50 text-xs">*/}
          {/*      &nbsp; Older than 12 months*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*<BrandEquity*/}
          {/*  stats={brands.stats.aged_reviews}*/}
          {/*  titles={[*/}
          {/*    'Total Positive Reviews',*/}
          {/*    'Total Neutral Reviews',*/}
          {/*    'Total Negative Reviews'*/}
          {/*  ]}*/}
          {/*/>*/}
          {/*</div>*/}
          <div className="mt-6">
            <div className="mb-2">Actions</div>
            <BrandEquity
              stats={brand.stats.actions}
              titles={[
                'Items with critical issues',
                'Items with poor reviews',
                'Items with too few reviews'
              ]}
              isLinkCard
              brandId={brandId}
              linkCard={['critical_issues', 'poor_reviews', 'too_few_reviews']}
            />
          </div>
        </>
      ) : isLoading ? (
        <Spin className="w-full flex justify-center pt-[50px]" />
      ) : (
        <div className="font-semibold text-center text-[larger] mt-[60px]">
          {userData?.brand_ids && userData?.brand_ids?.length > 1 && (
            <BackButton />
          )}
          Brand Not Fount
        </div>
      )}
    </div>
  )
}

export default Brand
