import React from 'react'
import { Spin } from 'antd'

interface LoaderProps {
  size?: 'small' | 'large' | 'default'
}
const Loader: React.FC<LoaderProps> = ({ size }) => {
  return (
    <div className="flex justify-center items-center h-screen w-full">
      <Spin size={size} />
    </div>
  )
}

export default Loader
