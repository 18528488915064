import React, { createContext, useState, useEffect } from 'react'
import { onSnapshot, doc, getFirestore } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { ICategoryData } from '../../containers/catogoryList/category.interface'

export type CategoryContextValue = {
  categoryData: ICategoryData | null
  isError: boolean
}
const CategoryContext = createContext<CategoryContextValue | null>(null)
export const CategoryProvider = ({ children }: any) => {
  const { categoryId } = useParams()
  const [categoryData, setCategoryData] = useState<ICategoryData | null>(null)
  const [isError, setIsError] = useState<boolean>(false)

  useEffect(() => {
    const db = getFirestore()
    if (categoryId) {
      try {
        const categoryRef = doc(db, 'categories', categoryId)
        const unsubscribeCategory = onSnapshot(categoryRef, (snapshot) => {
          if (snapshot.exists()) {
            // @ts-ignore
            setCategoryData(snapshot.data())
          }
        })

        return () => {
          unsubscribeCategory()
        }
      } catch (e) {
        setIsError(true)
      }
    }
  }, [categoryId])

  return (
    // @ts-ignore
    <CategoryContext.Provider value={{ categoryData, isError }}>
      {children}
    </CategoryContext.Provider>
  )
}

export default CategoryContext
